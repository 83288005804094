import React from 'react';


function Background() {
    return (
        <div>
            <img src="/abstract.jpg" className="w-full min-h-screen"  alt={'background'}/>
        </div>
    );
}

export default Background;